import { render } from "solid-js/web";
import { createSignal, onMount } from "solid-js";
import { Router, Routes, Route, A, useNavigate } from "@solidjs/router";

const [status, setStatus] = createSignal<
  "initial" | "pending" | "error" | "success"
>("initial");

const isAllowedCharacter = (c: string) => !isNaN(parseInt(c));

const LoginForm = () => {
  const navigate = useNavigate();
  return (
    <form
      class="flex flex-col w-full gap-1"
      onSubmit={(e) => {
        setStatus("pending");
        e.preventDefault();
        const data = new FormData(e.target as HTMLFormElement);
        fetch(
          `/sign-in/api/challenge/email?email=${
            Object.fromEntries(data).email
          }`,
          {
            method: "post",
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw res;
            }
            setStatus("success");
            if (res.status === 202) {
              navigate("/sign-in/approval");
            } else {
              navigate("/sign-in/code");
            }
          })
          .catch(() => {
            setStatus("error");
          });
      }}
    >
      <input
        name="email"
        class="form-control"
        type="email"
        placeholder="Your email address"
        autocomplete="email"
        required
      />
      <button
        disabled={status() === "pending"}
        type="submit"
        class="btn btn--wt-primary btn--vt-default"
      >
        {status() === "pending" ? "Sending..." : "Send sign-in link"}
      </button>
      {status() === "error" && <p>Something went wrong, sorry!</p>}
    </form>
  );
};

const CodeForm = () => {
  const [status, setStatus] = createSignal<
    "initial" | "pending" | "error" | "success"
  >("initial");
  let formRef: HTMLFormElement;
  const [errors, setErrors] = createSignal(null);

  const handleSubmit = () => {
    setStatus("pending");
    const { code } = Object.fromEntries(new FormData(formRef));
    setErrors(null);
    fetch(`/sign-in/api/token/otp/${code}`, { method: "post" })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        setStatus("success");
        window.location.assign("/");
      })
      .catch(async (e) => {
        setStatus("error");
        try {
          const response = await e.json();
          if (response.errors.length) {
            setErrors(response.errors);
          }
        } catch (e) {}
      });
  };
  const handleInput = (e) => {
    if (e.target.value.length === 6) {
      handleSubmit();
    }
  };
  const handleBeforeInput = (e: InputEvent) => {
    if (
      e.inputType === "insertText" &&
      e.data.length === 1 &&
      !isAllowedCharacter(e.data)
    ) {
      e.preventDefault();
    }
    if (
      e.inputType === "insertFromPaste" &&
      Array.from(e.data).some((c) => !isAllowedCharacter(c))
    ) {
      e.preventDefault();
      e.target.value = Array.from(e.data)
        .filter((c) => isAllowedCharacter(c))
        .join("")
        .slice(0, 6);
      // e.target.dispatchEvent(new Event("change"));
    }
  };
  const inputEl = (
    <input
      type="text"
      class="form-control"
      inputmode="numeric"
      name="code"
      placeholder="6-digit code"
      maxLength={6}
      minLength={6}
      pattern="\d*"
      onInput={handleInput}
      onBeforeInput={handleBeforeInput}
      autocomplete="one-time-code"
    />
  ) as HTMLInputElement;
  onMount(() => {
    inputEl.value =
      new URLSearchParams(window.location.search).get("code") || "";
  });

  return (
    <>
      <form
        ref={formRef}
        class="flex flex-col gap-1 w-full"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {inputEl}
        <button
          disabled={status() === "pending"}
          type="submit"
          class="btn btn--wt-primary btn--vt-default"
        >
          {status() === "pending" ? "Logging in..." : "Submit"}
        </button>
      </form>
      {status() === "error" && errors()?.length && (
        <p
          class="text-weight--emphasized"
          style={{ color: "var(--color--red--600)" }}
        >
          {errors()[0].title}
        </p>
      )}
    </>
  );
};

const ApprovalPage = () => {
  return (
    <>
      <p class="text-lg text-weight--emphasized">Approval Needed</p>
      <p style={{ "max-inline-size": "55ch" }}>
        Since you haven’t logged in before with this email address we’ll need to
        quickly verify that we know who you are 😄. You’ll receive a sign-in
        email once we’ve done that. Thanks!
      </p>
      <p>
        <small>
          <a href="/sign-in">Change my email address</a>
        </small>
      </p>
    </>
  );
};

const EmailEntry = () => {
  return (
    <>
      <p class="text-lg text-weight--emphasized">Sign in</p>
      <p>
        Before you can see this page you need to sign in with your e-mail
        address.
      </p>
      <LoginForm />
      <p>
        <small>
          <A href="/sign-in/code">Already have a code?</A>
        </small>
      </p>
    </>
  );
};

const CodeEntry = () => {
  return (
    <>
      <p class="text-lg text-weight--emphasized">Enter code</p>
      <p style={{ "max-inline-size": "55ch" }}>
        Please enter the code or use the sign-in link that you received in your
        email.
      </p>
      <CodeForm />
      <p>
        <small>
          <a href="/sign-in">Request a new code</a>
        </small>
      </p>
    </>
  );
};

render(
  () => (
    <Router>
      <main>
        <div
          class="flex items-center justify-center p-5"
          style="height: 100vh; box-sizing: border-box;"
        >
          <div class="flex flex-col items-center gap-1">
            <Routes>
              <Route path="/sign-in" component={EmailEntry} />
              <Route path="/sign-in/code" component={CodeEntry} />
              <Route path="/sign-in/approval" component={ApprovalPage} />
            </Routes>
          </div>
        </div>
      </main>
    </Router>
  ),
  document.getElementById("root")!
);
